import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React, {useState} from "react"
import { motion, AnimatePresence } from 'framer-motion'
import {musicHolder, releaseHolder, musicNav, releasesHolder} from './Pages.module.scss'
import { graphql } from "gatsby"
import Seo from "../components/utilities/Seo"


const Wrap = ({ condition, wrapper, children }) => 
condition ? wrapper(children) : children;


const Release = ({release, image})=> {
  const releaseVariants = {
    pre: {
      x: "-2rem",
      opacity:0
    },
    vis: {
      x: "0rem",
      opacity:1,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    },
    post: {
      x: "2rem",
      opacity:0,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut"
      }
    }
  }

  return (
    <motion.li className={releaseHolder} variants={releaseVariants}>
      <Wrap
        condition={release.links[0]?.url}
        wrapper={children => <a href={release.links[0].url} title={release.title} target="_blank" rel="noreferrer" >{children}</a>}
        >
        <GatsbyImage image={getImage(image)} alt={release.title} />
      </Wrap>

      <Wrap
        condition={release.links[0]?.url}
        wrapper={children => <a href={release.links[0].url} title={release.title} target="_blank" rel="noreferrer" >{children}</a>}
        >
      <div className="titleAndDate">
        
          <h3>{release.title}</h3>
      
        <p>{release.date}</p>
      </div>
      </Wrap>
      <ul className="links">
        {
          release.links.map(link=> (
          <li key={link.url}><a href={link.url} target="_blank" rel="noreferrer" >{link.text}</a></li>
          ))
        }
      </ul>
    </motion.li>
  )
}



export default function MusicPage({data}) {

  const switcherVariants = {
    selected: {
      "--underline-width": "100%"
    },
    unselected: {
      "--underline-width": "0%"
    }
  }

  const releasesVariants = {
    pre: {
      opacity:1
    },
    vis: {
      opacity:1,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut",
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    },
    post: {
      opacity:0,
      transition:{
        duration: 0.5,
        type:'tween',
        ease:"easeInOut",
        staggerChildren: 0.2,
        staggerDirection: 1
      }
    }
  }

  const [show, setShow] = useState('singles')

  const allMusic = data.allDataJson.nodes[0].music
  const singles = allMusic.filter(release => release.type === "single")
  const albums = allMusic.filter(release => release.type === "album")

  const allImages = data.allFile.nodes

  return (
    <article className={musicHolder}>
      <Seo title="Music"
      description="Kyla La Grange - releases"/>

      <section>
        <ul className={musicNav}>
          <li><motion.button onClick={()=>setShow('singles')} variants={switcherVariants} whileHover="selected" animate={ show === 'singles' ? "selected" : "unselected" }>Singles</motion.button></li>
          <li><motion.button onClick={()=>setShow('albums')} variants={switcherVariants} whileHover="selected" animate={ show === 'albums' ? "selected" : "unselected" } >Albums</motion.button></li>
        </ul>

        <div className={releasesHolder}>
          <AnimatePresence>
          {
            show === 'singles' &&
            ( singles?.length>0
              ?
              <motion.ul key="singles" id="singles" className="releases" variants={releasesVariants} initial="pre" exit="post" animate="vis">
              {
                singles.map(single => {
                  const image = allImages.find(img => img.name === single.image )

                  return <Release release={single} image={image} key={single.title} />
                })
              }
              </motion.ul>
              :
              <p>No singles found</p>
            )
          }
          {
            show === 'albums' &&
            ( albums?.length>0 
              ?
              <motion.ul key="albums" id="albums" className="releases" variants={releasesVariants} initial="pre" exit="post" animate="vis">
              {
                albums.map(album => {

                  const image = allImages.find(img => img.name === album.image )

                  return <Release key={album.title} release={album} image={image} />
                })
              }
              </motion.ul>
              :
              <p>No albums found</p>
            )
          }
          </AnimatePresence>
        </div>
      </section> 

    </article>
  )
}

export const query = graphql`
query MusicQuery {
  allDataJson {
    nodes {
      music {
        title
        date
        image
        type
        links {
          text
          url
        }
      }
    }
  }
  allFile(filter: {relativeDirectory: {eq: "music"}}) {
    nodes {
      relativeDirectory
      name
      childImageSharp {
        gatsbyImageData(
          width: 500
          height:500
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
}
`